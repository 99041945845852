import { Injectable } from "@angular/core";
import { QueryCache, QueryClient } from "@tanstack/angular-query-experimental";
import { GlobalErrorHandler } from "../../error-handler/error-handler";

@Injectable()
export class QueryClientFactory extends QueryClient{

    constructor(private globalErrorHandler:GlobalErrorHandler){
        
        super({
            queryCache:new QueryCache({
                onError:(error,query) => {
                    globalErrorHandler.handleError(error);
                    console.log('🚀 -> query:', query)
                    console.log('🚀 -> error:', error)
                    
                }
            })
        });
    }
}