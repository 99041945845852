import { registerLocaleData } from '@angular/common';
import {
  HttpClientModule,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import es from '@angular/common/locales/es';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom
} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  Router,
  provideRouter,
  withComponentInputBinding,
  withHashLocation,
  withViewTransitions,
} from '@angular/router';
import {
  BASE_URL_API,
  ENV_TOKEN,
  ErrorHandlerModule,
  ORG_ID,
  SITES_KEY,
} from '@carwash-project/modules/core';
import {
  AuthState,
  tokenInterceptor,
} from '@carwash-project/modules/data-access/auth';
import {
  CustomStorageEngine,
  StorageState,
} from '@carwash-project/modules/data-access/storage';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import {
  NavigationActionTiming,
  NgxsRouterPluginModule,
} from '@ngxs/router-plugin';
import { NgxsStoragePluginModule, STORAGE_ENGINE } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import * as Sentry from '@sentry/angular-ivy';
import {
  QueryClient,
  provideTanStackQuery,
  withDevtools
} from '@tanstack/angular-query-experimental';
import { QueryClientFactory } from 'modules/core/src/lib/services/query-client/query-client-factory';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { NZ_I18N, es_ES } from 'ng-zorro-antd/i18n';
import { NzModalService } from 'ng-zorro-antd/modal';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { environment } from '../env/env';
import { appRoutes } from './app.routes';

registerLocaleData(es);

if (environment.bugTracking) {
  Sentry.init({
    dsn: 'https://9a8f984c8ffc689683ae0709753563aa@o4506356416446464.ingest.sentry.io/4506356418936832',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/yourserver\.io\/api/,
        ],
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    enabled: environment.bugTracking,
  });
}


export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      appRoutes,
      withHashLocation(),
      withComponentInputBinding(),
      withViewTransitions({
        skipInitialTransition: true,
      })
    ),
    provideHttpClient(withInterceptors([tokenInterceptor])),
    { provide: NZ_I18N, useValue: es_ES },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: ENV_TOKEN,
      useValue: environment,
    },
    {
      provide: SITES_KEY,
      useValue: environment.siteKey,
    },
    {
      provide: BASE_URL_API,
      useValue: environment.urlApi,
    },
    {
      provide: ORG_ID,
      useValue: environment.orgID,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideEnvironmentNgxMask(),
    importProvidersFrom([
      HttpClientModule,
      BrowserAnimationsModule,
      ErrorHandlerModule.forRoot(),
      NgxsModule.forRoot([AuthState, StorageState]),
      NgxsRouterPluginModule.forRoot({
        navigationActionTiming: NavigationActionTiming.PostActivation,
      }),
      NgxsResetPluginModule.forRoot(),
      NgxsStoragePluginModule.forRoot({
        key: StorageState,
      }),
      NgxsReduxDevtoolsPluginModule.forRoot({
        disabled: environment.production,
      }),
      NgxsLoggerPluginModule.forRoot({
        disabled: true,
        collapsed: true,
      }),
    ]),
    {
      provide: STORAGE_ENGINE,
      useClass: CustomStorageEngine,
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.siteKey,
    },
    NzModalService,
    provideTanStackQuery(
      new QueryClient(),
      withDevtools(() => ({ loadDevtools: environment.showTankStackDevtools })),
    ),
    {
      provide: QueryClient,
      useClass: QueryClientFactory,
    },
  ],
};